.saved-news-header {
  padding: 40px 104px;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

.saved-news-header__title {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #8c8d90;
  margin: 0 0 28px;
  padding: 0;
}

.saved-news-header__text {
  font-family: Robotoslab, 'Times New Roman', Times, serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  color: #1A1B22  ;
  margin: 0 0 30px;
  padding: 0;
  max-width: 530px;
}

.saved-news-header__subtitle {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1A1B22;
  margin: 0;
  padding: 0;
}

.saved-news-header__subtitle_key {
  font-weight: 700;
}


@media screen and (max-width: 1150px) {
  .saved-news-header {
    padding: 24px 40px 32px;
  }

  .saved-news-header__title {
    font-size: 14px;
    margin-bottom: 16px;
    padding: 0;
  }

  .saved-news-header__text {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 16px;
    max-width: 350px;
  }
  
  .saved-news-header__subtitle {
    font-size: 14px;
  }
}
