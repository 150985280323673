.footer {
  display: flex;
  justify-content: space-between;
  padding: 32px 104px 26px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  align-items: center;
}

.footer__copyright {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #B6BCBF;
}

.footer__nav {
  display: flex;
}

.footer__text-links {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.footer__icon-links {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer__text-link-container {
  margin: 0;
  padding: 0 40px 0 0;
}

.footer__icon-link-container {
  margin: 0;
  padding: 0 0 0 24px;
}

.footer__text-link {
  color: #1A1B22;
  text-decoration: none;
  transition: 0.2s;
}

.footer__text-link:hover {
  opacity: 0.7;
}


.footer__icon-link {
  transition: 0.2s;
}

.footer__icon-link:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1150px) {
  .footer {
    padding: 24px 40px 18px;
  }

  .footer__text-link-container {
    padding-right: 20px;
  }
  
  .footer__icon-link-container {
    padding-left: 12px;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    flex-direction: column-reverse;
    align-items: stretch;
  }

  .footer__nav {
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 550px) {
  .footer {
    padding-bottom: 22px;
  }

  .footer__text-links {
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  .footer__nav {
    margin-bottom: 0;
  }

  .footer__text-link-container {
    margin-bottom: 40px;
  }

  .footer__copyright {
    text-align: center;
  }
}

@media screen and (max-width: 420px) {
  .footer {
    padding-left: 16px;
    padding-right: 16px;
  }
}
