.popup {
  z-index: 300;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s ease 0.5s, opacity 0.5s ease;

  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.popup__container {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 34px 36px 28px;
  max-width: 430px;
  min-width: 240px;
  width: calc(100% - 80px);
  background-color: #ffffff;
  color: #1A1B22;
  border-radius: 16px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
}

.popup__title {
  margin: 0 0 22px;
  padding: 0;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.popup__close-btn {
  position: absolute;
  right: -45px;
  top: -45px;

  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: url(../../images/icons/close-white.svg);
  background-position: center;
  background-size: cover;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: 0.2s;
}

.popup__close-btn:hover {
  opacity: 0.6;
}

.popup__close-btn:focus {
  outline: none;
}

.popup__link {
  display: inline-block;
  margin-top: 0;
  color: #2F71E5;
  font-size: 18px;
  cursor: pointer;
  transition: 0.2s;
}

.popup__link:hover {
  opacity: 0.7;
}

.popup__submit-btn {
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0px 5px 15px rgba(14, 26, 57, 0.2);
}

.popup__submit-btn:disabled {
  background-color: #E6E8EB;
  color: #B6BCBF;
  box-shadow: none;
  pointer-events: none;
}

.popup__input {
  margin: 0 0 3px;
  padding: 0;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  min-height: 30px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: transparent;
  caret-color: #2F71E5;
}

.popup__input:focus {
  outline: none;
  border-bottom-color: black;
}

.popup__input-title {
  color: #2F71E5;
  font-size: 12px;
  line-height: 15px;
}

.popup__input-error {
  font-size: 12px;
  line-height: 15px;
  color: #ff0000;
  transition: 0.3s;
}

.popup__form-error {
  align-self: center;
  min-height: 20px;
  margin-bottom: 3px;
  font-size: 12px;
  line-height: 15px;
  color: #ff0000;
  transition: 0.3s;
}

.popup__field {
  display: flex;
  flex-direction: column;
  min-height: 70px;
}

.popup__alt-submit-text {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.popup__alt-submit-link {
  color: #2F71E5;
  text-decoration: none;
  cursor: pointer;
}

.popup__alt-submit-link:hover {
  opacity: 0.7;
}

@media screen and (max-width: 550px) {
  .popup {
    align-items: flex-start;
    top: 56px;
  }

  .popup__close-btn {
    right: 0;
    top: -40px;
    width: 24px;
    height: 24px;
  }

  .popup__close-btn_theme_light {
    background-image: url(../../images/icons/close-black_small.svg);
  }
} 

@media screen and (max-width: 510px) {
  .popup__close-btn {
    right: 16px;
  }

  .popup__container {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
} 

@media screen and (max-width: 420px) {
.popup__container {
  padding-left: 16px;
  padding-right: 16px;
}

  .popup__close-btn {
    right: 16px;
  }

  .popup__container {
    width: 100%;
    max-width: 100%;
  }
} 
