.card {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border-radius: 16px;
  overflow: hidden;
  background-color: white;
  color: #1A1B22;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

.card__widgets-container {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-start;
  padding: 24px;
  left: 0;
  right: 0;
  top: 0;
  height: 272px;
  overflow: hidden;
}

.card__widgets-wrapper {
  display: flex;
  flex-grow: 2;
  flex-direction: row-reverse;
  align-items: flex-start;
}

.card__widget {
  display: none;
  min-width: 40px;
  min-height: 40px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 8px;
}

.card__widget_content_icon {
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.2s;
}

.card__widget_content_text {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.2;
  text-align: center;
  color: #000000;
  padding: 12px;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.card__widget_key-feature {
  font-size: 12px;
  max-width: calc(100% - 50px);
}

.card__widget_visible {
  display: flex;
}

.card__widget_icon_trash {
  background-image: url(../../images/icons/trash-normal.svg);
}

.card__widget_icon_trash:hover {
  background-image: url(../../images/icons/trash-hover.svg);
}

.card__widget_icon_trash:active {
  background-color: #dddddd;
}

.card__widget_icon_bookmark {
  background-image: url(../../images/icons/bookmark-normal.svg);
}

.card__widget_icon_bookmark:hover {
  background-image: url(../../images/icons/bookmark-hover.svg);
}

.card__widget_icon_bookmark:active {
  background-color: #dddddd;
}

.card__widget_icon_bookmark-marked {
  background-image: url(../../images/icons/bookmark-marked.svg);
}

.card__widget_icon_bookmark-marked:active {
  background-image: url(../../images/icons/bookmark-normal.svg);
}


.card__image {
  width: 100%;
  height: 272px;
  object-fit: cover;
  object-position: center;
}

.card__bottom-container {
  margin: 0;
  padding: 20px 24px 24px;
  text-decoration: none;
  color: #1A1B22;
}

.card__header {
  color: #B6BCBF;
  font-family: 'Sourcesanspro', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 12px;
  padding: 0;
}

.card__article {
  position: relative;
  height: 194px;
  margin: 0 0 10px;
  padding: 0;
  overflow: hidden;
}

/* .card__article::after {
  content: '';
  position: absolute;
  right: 0; bottom: 0;
  width: 100%;
  height: 20%;
  background: linear-gradient(to bottom, rgba(255,255,255, 0.0), #fff 100%);
} */

.card__title {
  font-family: 'Robotoslab', 'Times New Roman', Times, serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  margin: 0 0 16px;
  padding: 0;
}

.card__text {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #1A1B22;
}

.card__footer {
  font-family: 'Robotoslab', 'Times New Roman', Times, serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #B6BCBF;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1440px) {
  .card__image .card__widgets-container {
    height: calc(25vw - 88px);
  }
}

@media screen and (max-width: 1150px) { 
  .card__image .card__widgets-container {
    height: calc(25vw - 60px);
  }

  .card__widgets-container {
    padding: 8px;
  }

  .card__bottom-container {
    padding: 16px;
  }

  .card__article {
    height: 160px;
  }

  .card__title {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1024px) { 
  .card__article {
    height: 190px;
  }
}

@media screen and (max-width: 767px) { 
  .card__image .card__widgets-container {
    height: calc(40vw - 60px);
  }
}

@media screen and (max-width: 550px) { 
  .card__image .card__widgets-container {
    height: 60vw;
  }
  
  .card__article {
    height: 160px;
  }
}

@media screen and (max-width: 420px) {  
  .card__article {
    height: 150px;
  }
}