.nav {
  display: flex;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

.nav__container {
  display: flex;
}

.nav__links {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.nav__link-container {
  display: flex;
}

.nav__link {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  margin-right: 34px;
  transition: 0.2s;
}

.nav__link_theme_light {
  color: black;
}



.nav__link:hover {
  opacity: 0.7;
}

.nav__link_active {
  box-shadow: inset 0px -3px 0px;
}

.nav__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  height: 48px;
  color: #ffffff;
  background-color: transparent;
  border-radius: 24px;
  padding: 0 24px;
  align-self: center;
  border: 1px solid #ffffff;
  cursor: pointer;
  transition: 0.2s;
}

.nav__btn:hover {
  opacity: 0.7;
}

.nav__btn:focus {
  outline: none;
}

.nav__btn_theme_light {
  color: black;
  border-color: black;
}

.nav__btn-image {
  padding-left: 10px;
}

.nav__btn_hidden {
  display: none;
}

@media screen and (max-width: 1150px) {
  .nav__link {
    font-size: 16px;
    margin-right: 16px;
  }

  .nav__btn {
    font-size: 16px;
    border-radius: 20px;
    height: 40px;
  }
}

@media screen and (max-width: 767px) {
  .nav {
    align-items: flex-start;
    visibility: hidden;
    opacity: 0;
    /* display: none; */
    position: fixed;
    top: 0;
    top: 56px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
    transition: 0.2s;
  }

  .nav_expanded {
    visibility: visible;
    opacity: 1;
    /* display: flex; */
  }

  .nav__container {
    background-color: #1A1B22;
    width: 100%;
    padding: 30px 0 24px;
    flex-direction: column;
    /* align-items: stretch; */
    box-sizing: border-box;
  }

  .nav__container_theme_light {
    background-color: #fff;
  }

  .nav__links {
    flex-direction: column;
    align-items: stretch;
  }

  .nav__link-container {
    min-height: 65px;
    justify-content: center;
  }

  .nav__link-container:last-child {
    margin-bottom: 20px;
  }

  .nav__link {
    margin-right: 0px;
    width: 100%;
    justify-content: center;
    transition: 0.2s;
  }

  .nav__link:active {
    background-color: #0d0d11;
  }

  .nav__link_active {
  box-shadow: none;
  }

  .nav__btn {
    font-size: 18px;
    border-radius: 28px;
    height: 56px;
    width: calc(100% - 80px);
    max-width: 452px;
  }
}

@media screen and (max-width: 420px) {
  .nav__btn {
    width: calc(100% - 32px);
  }

  .nav__link {
    justify-content: flex-start;
    padding-left: 16px;
  }
}
