.preloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F5F6F7;
  min-height: 282px;
}

.preloader__caption {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #B6BCBF;
  margin: 0;
  padding: 0;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

.circle-preloader {
	width: 74px;
	height: 74px;
	border: 1px solid #444;
	border-bottom-color: #888;
	border-radius: 50%;
	animation: spin .75s infinite linear;
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}

@media screen and (max-width: 767px) { 
  .preloader {
    min-height: 200px;
  }
}