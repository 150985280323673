.page {
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  align-content: space-between;
  max-width: 1440px;
  min-height: 100vh;
  margin: 0 auto;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

.page_fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
