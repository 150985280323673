@import url(./vendor/fonts/fonts.css);
@import url(./vendor/normalize.css);

.btn {
  display: block;
  border-radius: 32px;
  height: 64px;
  padding: 0 32px;
  color: #1A1B22;
  background-color: #ffffff;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

.btn:focus {
  outline: none;
}

.btn:hover {
background-color: #e5e5e5;
}

.btn_type_submit {
  background-color: #2F71E5;
  color: #ffffff;
}

.btn_type_submit:hover {
  background-color: #347EFF;
}

.btn_type_submit:active {
  background-color: #2A65CC;
}
